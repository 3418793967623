// Home.js
import React, { useState } from "react";
import "./Home.css";
import TakeAdmission from "./TakeAdmission";
import note from "./2.avif";
import im1 from "./5.jpeg";
import im2 from "./6.jpeg";
import html from "./html.png";
import React1 from "./React1.webp";
import python1 from "./python1.webp";
import linux1 from "./linux1.png";
import javascript from "./javascript.webp";
import css from "./css.png"
import reactnative from "./reactnative.svg"
import expressjs from "./expressjs.png"
import fastapi from "./fastapi.png";
import flask from "./flask.png";
import MongoDB from "./mogodb.png"
import sql from "./sql.png";
import docker from "./docker.png";
import kubernetes from "./kubernetes.png";
import jenkins from "./jenkins.jpg";
import git from "./git.png";
import github from "./github.png";
import jira from "./jira.png";
import AI from "./AI.png";
import machinelearning from "./machinelearning.jpg";
import deeplearning from "./deeplearning.jpg";
import supervised from "./supervised.png";
import unsupervised from "./unsupervised.jpeg";
import neuralnetwork from "./neuralnetwork.png";


const Home = () => {
  const [showAdmissionForm, setShowAdmissionForm] = useState(false);

  const handleAdmissionButtonClick = () => {
    setShowAdmissionForm(true);
  };

  const handleCloseAdmissionForm = () => {
    setShowAdmissionForm(false);
  };

  return (
    <div>
      {/* code for company name and admission button  */}
      <div className="homecontent">
        <div className="tagline">
          "Secure a Position as a <br />
          <span className="highlight">
            Full-Stack, End-to-End Artificial Intelligence Software Developer
          </span><br />
          with a <span className="highlight1">Salary of One Lakh per Month</span>, Step by Step"
        </div>

        <div className="button-container">
          {!showAdmissionForm && (
            <button
              className="admission-button"
              onClick={handleAdmissionButtonClick}
            >
              Take Admission
            </button>
          )}

        </div>
      </div>

      {/* <div className="note-container">
        <div className="note-title">Important Note for Students</div>
        <div className="note-content">
          <strong>Kind Reminder </strong>: We are a <strong> <u>service and product-based software company</u> </strong> that also provides education in the programming field. Our aim is to bridge the gap between theoretical knowledge and practical application, ensuring our students are well-prepared for the industry.
        </div>
      </div> */}

      {showAdmissionForm && (
        <TakeAdmission onClose={handleCloseAdmissionForm} />
      )}


      {/* code for video content */}
      <div className="videonote">

        <div className="impnote">
          <div className="note-container">
            <img src={note} alt="Important Note" className="note" />
          </div>
        </div>


        <div className="videocontent">
          <iframe
            src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Example video
            title="Video Content"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>





      </div>


      <div class="heading-container">
        <h2 class="heading-title">Why Choose Us?</h2>
        <div class="heading-underline"></div>
      </div>

      <div className="card-out">


        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Software Company Enrich In Education</h3>
            </div>
            <div class="description-section">
              <p>  We are not just a teaching institute; we are a distinct entity engaged in both service-based and product-based industries.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">समर्पण</button>
            </div>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Pathway to Global IT Careers</h3>
            </div>
            <div class="description-section">
              <p> Our distinguished and experienced board of directors, along with global pioneers in skill enhancement, ensure the successful placement of students in the IT industry worldwide.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">संघर्ष</button>
            </div>
          </div>
        </div>

        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Driving Excellence in IT and Beyond</h3>
            </div>
            <div class="description-section">
              <p> Our mentor, who is the CEO of his own company, is currently employed while also leading two successful enterprises: Sneh-Achievers and another service and product-based company in the IT industry.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">सिद्धि</button>
            </div>
          </div>
        </div>

      </div>

      <div className="card-out">


        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Guiding Students to Flourishing Careers </h3>
            </div>
            <div class="description-section">
              <p>As both an employee and employer, our CEO possesses a unique perspective and vision, ensuring students are placed in healthy and thriving environments.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">निष्ठा</button>
            </div>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Real-Time Learning and Industry Engagement</h3>
            </div>
            <div class="description-section">
              <p> It's not just about teaching and learning; it's about engaging in real-time, globally standardized live product development within the industry.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">सयंम </button>
            </div>
          </div>
        </div>

        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Equipping Students for Competitive Edge</h3>
            </div>
            <div class="description-section">
              <p>  Not only does our program provide students with essential skillsets, but it also offers valuable industry experience, which is a key concern for interviewers.</p>
            </div>
            <div class="action-section">
              <button class="cta-button"> प्रतिबद्धता</button>
            </div>
          </div>
        </div>

      </div>
 
      <div className="card-out">


        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Partnering for Real-World Experience</h3>
            </div>
            <div class="description-section">
              <p> As we develop Proof of Concepts (POCs) for various products in collaboration with partner companies, our students gain a competitive edge.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">कर्मयोग</button>
            </div>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Commitment to Your Success</h3>
            </div>
            <div class="description-section">
              <p> We do not make false promises, but we guarantee that you will achieve your placement goals, and we will support you until that time.</p>
            </div>
            <div class="action-section">
              <button class="cta-button"> सफलता</button>
            </div>
          </div>
        </div>

        <div class="card-wrapper">
          <div class="info-card">
            <div class="card-title">
              <h3>Empowered by Inspiring Backgrounds</h3>
            </div>
            <div class="description-section">
              <p> Our CEO and teachers have inspiring backgrounds, exemplifying how dedication and hard work can lead to extraordinary achievements.</p>
            </div>
            <div class="action-section">
              <button class="cta-button">विजय</button>
            </div>
          </div>
        </div>

      </div>







      <div class="technologies">
        <div class="heading-container">
          <h2 class="heading-title">Technologies We Offer</h2>
          <div class="heading-underline"></div>
        </div>


        <div className="technology">
          <div className="tech1">
            <div class="tech-container">
              <div class="tech-logo">
                <img src={React1} alt="React Logo" />
              </div>
              <div class="tech-name">
                <h3>React JS</h3>
              </div>
            </div>


            <div class="tech-container">
              <div class="tech-logo">
                <img src={reactnative} alt="reactnative Logo" />
              </div>
              <div class="tech-name">
                <h3>ReactNative</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div class="tech-container">
              <div class="tech-logo">
                <img src={javascript} alt="javascript Logo" />
              </div>
              <div class="tech-name">
                <h3>Javascript</h3>
              </div>
            </div>

            <div class="tech-container">
              <div class="tech-logo">
                <img src={css} alt="css Logo" />
              </div>
              <div class="tech-name">
                <h3>CSS</h3>
              </div>
            </div>
          </div>


        </div>


        <div className="technology">

<div className="tech1">
<div class="tech-container">
            <div class="tech-logo">
              <img src={html} alt="html Logo" />
            </div>
            <div class="tech-name">
              <h3>HTML</h3>
            </div>
          </div>


          <div class="tech-container">
            <div class="tech-logo">
              <img src={python1} alt="Python1 Logo" />
            </div>
            <div class="tech-name">
              <h3>Python</h3>
            </div>
          </div>
</div>
       
<div className="tech2">
<div class="tech-container">
            <div class="tech-logo">
              <img src={expressjs} alt="expressjs Logo" />
            </div>
            <div class="tech-name">
              <h3>ExpressJS</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={fastapi} alt="fastapi Logo" />
            </div>
            <div class="tech-name">
              <h3>FastAPI</h3>
            </div>
          </div>
</div>
        
        </div>



        <div className="technology">
<div className="tech1">
<div class="tech-container">
            <div class="tech-logo">
              <img src={flask} alt="flask Logo" />
            </div>
            <div class="tech-name">
              <h3>Flask</h3>
            </div>
          </div>


          <div class="tech-container">
            <div class="tech-logo">
              <img src={MongoDB} alt="MongoDB Logo" />
            </div>
            <div class="tech-name">
              <h3>MongoDB</h3>
            </div>
          </div>
</div>
      
<div className="tech2">
<div class="tech-container">
            <div class="tech-logo">
              <img src={sql} alt="sql Logo" />
            </div>
            <div class="tech-name">
              <h3>SQL</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={docker} alt="docker Logo" />
            </div>
            <div class="tech-name">
              <h3>Docker</h3>
            </div>
          </div>
</div>
        </div>



        <div className="technology">
<div className="tech1">
<div class="tech-container">
            <div class="tech-logo">
              <img src={kubernetes} alt="kubernetes Logo" />
            </div>
            <div class="tech-name">
              <h3>Kubernetes</h3>
            </div>
          </div>


          <div class="tech-container">
            <div class="tech-logo">
              <img src={jenkins} alt="jenkins Logo" />
            </div>
            <div class="tech-name">
              <h3>Jenkins</h3>
            </div>
          </div>
</div>
          
<div className="tech2">
<div class="tech-container">
            <div class="tech-logo">
              <img src={git} alt="git Logo" />
            </div>
            <div class="tech-name">
              <h3>Git</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={github} alt="github Logo" />
            </div>
            <div class="tech-name">
              <h3>github</h3>
            </div>
          </div>
</div>
         



        </div>




        <div className="technology">
<div className="tech1">
<div class="tech-container">
            <div class="tech-logo">
              <img src={jira} alt="jira Logo" />
            </div>
            <div class="tech-name">
              <h3>Jira</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={linux1} alt="linux1 Logo" />
            </div>
            <div class="tech-name">
              <h3>Linux</h3>
            </div>
          </div>
</div>
         

<div className="tech2">
<div class="tech-container">
            <div class="tech-logo">
              <img src={AI} alt="AI Logo" />
            </div>
            <div class="tech-name">
              <h3>Artificial Intelligence</h3>
            </div>
          </div>



          <div class="tech-container">
            <div class="tech-logo">
              <img src={machinelearning} alt="machinelearning Logo" />
            </div>
            <div class="tech-name">
              <h3>Machine Learning</h3>
            </div>
          </div>
</div>
         


        </div>





        <div className="technology">
<div className="tech1">
<div class="tech-container">
            <div class="tech-logo">
              <img src={deeplearning} alt="deeplearning Logo" />
            </div>
            <div class="tech-name">
              <h3>Deep Learning</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={supervised} alt="supervised Logo" />
            </div>
            <div class="tech-name">
              <h3>Supervised Learning</h3>
            </div>
          </div>
</div>
        

<div className="tech2">
<div class="tech-container">
            <div class="tech-logo">
              <img src={unsupervised} alt="unsupervised Logo" />
            </div>
            <div class="tech-name">
              <h3>Unsupervised Intelligence</h3>
            </div>
          </div>

          <div class="tech-container">
            <div class="tech-logo">
              <img src={neuralnetwork} alt="neuralnetwork Logo" />
            </div>
            <div class="tech-name">
              <h3>Neural Network</h3>
            </div>
          </div>
</div>
         

        </div>

      </div>








      <div className="projectsection">

        <div class="heading-container">
          {/* 
          <h2 class="heading-title">Our Project</h2>
          <div class="heading-underline"></div> */}

        </div>
        {/* </div> */}

        {/* <div className="farm-container">
          <img src={im1} alt="Important Note" className="farm" />
        </div>

        <div className="farm-container">
          <img src={im2} alt="Important Note" className="farm" />
        </div> */}


      </div>
    </div>
  );
};

export default Home;
