import React from 'react';
import "./Business.css";

const Business = () => {
  return (
    <div className="business-container">
      <h1>Business Enquiry</h1>

      <div className="business-layout">
        {/* Left Side: Four Boxes */}
        <div className="info-boxes">
          <div className="info-box">
            <i className="fas fa-phone-alt"></i>
            <h2>Contact Number</h2>
            <a href="tel:+918208937591">+91 82089 37591</a>
          </div>
          <div className="info-box">
            <i className="fas fa-envelope"></i>  {/* Email Icon */}
            <h2>Email</h2>
            <a href="mailto:snehalsp65@gmail.com">snehalsp65@gmail.com</a>
          </div>
          <div className="info-box">
            <i className="fas fa-map-marker-alt"></i>  {/* Address Icon */}
            <h2>Address</h2>
            <p>Office No . 1010 Jasmine -D Lodha Crown Taloja, Pin-code 421204</p>
          </div>

          <div className="info-box">
            <i className="fas fa-linkedin"></i>  {/* LinkedIn Icon */}
            <h2>LinkedIn</h2>
            <a href="https://www.linkedin.com">LinkedIn Profile</a>
          </div>
        </div>

        {/* Right Side: Form */}
        <div className="form-container">
          <h2>Connect with Us to Hire Skilled Students</h2>
          <form className="form1">
            <label htmlFor="email">Email Address:</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />

            <label htmlFor="phone">Contact Number:</label>
            <input type="tel" id="phone" name="phone" placeholder="Enter your contact number" required />

            <label htmlFor="company">Name of the Company:</label>
            <input type="text" id="company" name="company" placeholder="Enter your Company Name" required />

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Business;





