import React, { useState, useEffect } from "react";
import "./MyProfile.css"; // Import CSS file for styling
import API_BASE_URL from './config'; 

function MyProfile() {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [GetuserDetails, setGetUserDetails] = useState(null);

  const [userDetails, setUserDetails] = useState({
    firstname: "NA",
    lastName: "NA",
    email: "NA",
    mobileNumber: "NA",
    education: "NA",
    // Add more details as needed
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const studentUniqueId = localStorage.getItem("student_unique_id");
        const token = localStorage.getItem("token");
        if (studentUniqueId && token) {
          const response = await fetch(
            `${API_BASE_URL}/student_info/${studentUniqueId}?token=${token}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if (response.ok) {
            console.log("Fetched Data Is :");
            const data = await response.json();
            setGetUserDetails(data);
            console.log(data);
            setUserDetails({
              firstname: data.first_name,
              lastName: data.last_name,
              email: data.email,
              mobileNumber: data.mobile_number,
              education: data.education,
              // Add more details as needed
            });
          } else {
            console.error("Failed to fetch user details:", response.statusText);
          }
        } else {
          console.error("Student Unique ID not found in local storage");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  // State to track edit mode for each field
  const [editMode, setEditMode] = useState({
    firstname: false,
    lastName: false,
    email: false,
    mobileNumber: false,
    education: false,
    // Add more fields as needed
  });

  // Function to handle edit mode toggle
  const toggleEditMode = (field) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
  };

  // Function to handle input change
  const handleInputChange = (e, field) => {
    setUserDetails({ ...userDetails, [field]: e.target.value });
  };

  const handleSave = async (field) => {
    console.log("handle save click");
    toggleEditMode(field);
    try {
      const studentUniqueId = localStorage.getItem("student_unique_id");
      const token = localStorage.getItem("token");
      if (studentUniqueId && token) {
        const response = await fetch(
          `${API_BASE_URL}/update_student_info/${studentUniqueId}?token=${token}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ [field]: userDetails[field] }),
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to update ${field}: ${response.statusText}`);
        }
      } else {
        console.error("Student Unique ID not found in local storage");
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="user-details-container">
          {/* Image on one side */}
          <div className="user-image">
            <img src="logo512.png" alt="User" />
          </div>

          {/* Details section */}
          <div className="user-details-section">
            {/* First Name */}
            <div className="detail">
              <div className="div1">
                <label>First Name:</label>
              </div>
              <div className="div2">
                {editMode.firstname ? (
                  <input
                    type="text"
                    value={userDetails.firstname}
                    onChange={(e) => handleInputChange(e, "firstname")}
                  />
                ) : (
                  <span>{userDetails.firstname}</span>
                )}
              </div>
              <div className="div3">
                {editMode.firstname ? (
                  <button
                    className="change-btn"
                    onClick={() => handleSave("firstname")}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="change-btn"
                    onClick={() => toggleEditMode("firstname")}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            {/* Last Name */}
            <div className="detail">
              <div className="div1">
                <label>Last Name:</label>
              </div>
              <div className="div2">
                {editMode.lastName ? (
                  <input
                    type="text"
                    value={userDetails.lastName}
                    onChange={(e) => handleInputChange(e, "lastName")}
                  />
                ) : (
                  <span>{userDetails.lastName}</span>
                )}
              </div>
              <div className="div3">
                {editMode.lastName ? (
                  <button
                    className="change-btn"
                    onClick={() => handleSave("lastName")}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="change-btn"
                    onClick={() => toggleEditMode("lastName")}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            {/* Email */}
            <div className="detail">
              <div className="div1">
                <label>Email:</label>
              </div>
              <div className="div2">
                {editMode.email ? (
                  <input
                    type="text"
                    value={userDetails.email}
                    onChange={(e) => handleInputChange(e, "email")}
                  />
                ) : (
                  <span>{userDetails.email}</span>
                )}
              </div>
              <div className="div3">
                {editMode.email ? (
                  <button
                    className="change-btn"
                    onClick={() => handleSave("email")}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="change-btn"
                    onClick={() => toggleEditMode("email")}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            {/* Mobile Number */}
            <div className="detail">
              <div className="div1">
                <label>Mobile Number:</label>
              </div>
              <div className="div2">
                {editMode.mobileNumber ? (
                  <input
                    type="text"
                    value={userDetails.mobileNumber}
                    onChange={(e) => handleInputChange(e, "mobileNumber")}
                  />
                ) : (
                  <span>{userDetails.mobileNumber}</span>
                )}
              </div>
              <div className="div3">

                {/* {editMode.mobileNumber ? (
                  <button
                    className="change-btn"
                    onClick={() => handleSave("mobileNumber")}
                  >
                    Save
                  </button>
                ) : ( */}
                  {/* <button
                    className="change-btn"
                    // onClick={() => toggleEditMode("mobileNumber")}
                  >
                    NAN
                  </button> */}
                {/* )} */}
              </div>
            </div>

            {/* Education */}
            <div className="detail">
              <div className="div1">
                <label>Education:</label>
              </div>
              <div className="div2">
                {editMode.education ? (
                  <input
                    type="text"
                    value={userDetails.education}
                    onChange={(e) => handleInputChange(e, "education")}
                  />
                ) : (
                  <span>{userDetails.education}</span>
                )}
              </div>
              <div className="div3">
                {editMode.education ? (
                  <button
                    className="change-btn"
                    onClick={() => handleSave("education")}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="change-btn"
                    onClick={() => toggleEditMode("education")}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            {/* Add more details as needed */}
          </div>
        </div>
      )}
    </div>
  );
}

export default MyProfile;
