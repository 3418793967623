import React, { useState, useEffect, useContext } from "react";
import "./NavBar2.css";
import ProgressContext from './ProgressContext';
import API_BASE_URL from './config'; 

const Navbar2 = ({ setPage }) => {
  const { progress } = useContext(ProgressContext);
  const [DayDuration, setDayDuration] = useState("NA");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const studentUniqueId = localStorage.getItem("student_unique_id");
        const token = localStorage.getItem("token");
        if (studentUniqueId && token) {
          const response = await fetch(
            `${API_BASE_URL}/student_JoinDate/${studentUniqueId}?token=${token}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            setDayDuration(data["days_difference"]);
          } else {
            console.error("Failed to fetch details:", response.statusText);
          }
        } else {
          console.error("Student Unique ID not found in local storage");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handlelogout=()=>{
    localStorage.removeItem("student_unique_id");
    localStorage.removeItem("token");
    window.location.href = 'http://localhost:3000';
  }

  return (
    <>
      <div className="nav-outer">
        <div className="progress-div">
          <div className="progress-label">Progress: </div>
          <div className="inner-progress">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
              {`${Number(progress).toFixed(2)} %`}
            </div>
          </div>
          <div className="day-count"> Day - {DayDuration}</div>
          <div><span className="cl-btn"><button className="cl-button" onClick={handlelogout}>Logout </button></span></div>
        </div>

        <nav className="NavMain2">
          <button
            className="NavButton2"
            onClick={() => setPage("CompleteSyllabus")}
          >
            Complete Syllabus
          </button>
          <button className="NavButton2" onClick={() => setPage("Assignment")}>
            Assignment
          </button>
          <button className="NavButton2" onClick={() => setPage("Placement")}>
            Placement
          </button>
          <button className="NavButton2" onClick={() => setPage("MyProfile")}>
            My Profile
          </button>
        </nav>
      </div>
    </>
  );
};

export default Navbar2;
