import React, { useState } from "react";
import Navbar from "./NavBar";
import Home from "./Home";
import StudentLogin from "./StudentLogin";
import PlacementProcess from "./PlacementProcess";
import Business from "./Business";
import { ProgressProvider } from "./ProgressContext"; // Import the ProgressProvider

function App() {
  const [currentPage, setCurrentPage] = useState("Home");

  const renderPage = () => {
    // console.log(currentPage)
    switch (currentPage) {
      case "StudentLogin":
        return <StudentLogin setCurrentPage={setCurrentPage} />;  // Pass setCurrentPage as a prop
      case "PlacementProcess":
        return <PlacementProcess />;
      case "Business":
        return <Business/>;
      default:
      case "Home":
        return <Home />;
    }
  };

  return (
    <ProgressProvider>
      <div className="uppar-nav">
        <div className="NavNames">
          <Navbar className="NavNames" setPage={setCurrentPage} />
        </div>
      </div>
      {renderPage()}
    </ProgressProvider>
  );
}

export default App;
