// For Local
// const API_BASE_URL = "http://127.0.0.1:8000";

// For remote access

const API_BASE_URL = "https://snehachievers.in/api"

// For Kubernets
// const API_BASE_URL = "http://192.168.49.2:31699"

// const API_BASE_URL = "https://3a91-2402-e280-3da4-12c-9bfb-f308-23ee-e7ab.ngrok-free.app";

export default API_BASE_URL;
