import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { FaVolumeHigh } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import "./ViewVideo.css";
import API_BASE_URL from './config'; 

const ViewVideo = (props) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const playerRef = useRef(null);
  const filename = props.props;
  // const url = `${API_BASE_URL}/sftp_video/${filename}`;
  const url = `${API_BASE_URL}/video/${filename}`;
  console.log("video url is:", url)

  useEffect(() => {
    setPlaying(true); // Auto play the video when component mounts
  }, []);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleDuration = (d) => {
    setDuration(d);
  };

  const handleSeekChange = (e) => {
    const fraction = parseFloat(e.target.value);
    setPlayed(fraction);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    const fraction = parseFloat(e.target.value);
    const newPositionInSeconds = fraction * duration;
    playerRef.current.seekTo(newPositionInSeconds);
  };

  const handleFullscreen = () => {
    playerRef.current?.getInternalPlayer()?.requestFullscreen();
  };

  return (
    <>
      <div className="player-container">
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          volume={volume}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          width="100%"
          height="60vh"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          className="react-player"
        />
      </div>

      <div>
        <div className="fun-btn">
          <button className="controlButton" onClick={handlePlayPause}>
            {playing ? "Pause" : "Play"}
          </button>
          <button className="controlButton" onClick={handleFullscreen}>
            Fullscreen
          </button>
          <div className="volume-fun">
            <div>
              <FaVolumeHigh />
            </div>
            <div>
              <input
                type="range"
                className="volumeSlider"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={handleVolumeChange}
              />
            </div>
          </div>

          <div className="progress-fun">
            <div>
              <GiProgression />
            </div>
            <div>
              <input
                type="range"
                className="progressSlider"
                min={0}
                max={1}
                step="any"
                value={played}
                onChange={handleSeekChange}
                onMouseDown={handleSeekMouseDown}
                onMouseUp={handleSeekMouseUp}
              />
            </div>
          </div>
        </div>
      </div>


      <div class="doubt-section">
        <h2>Ask a Doubt</h2>
        <form id="doubtForm">
          <textarea id="doubtInput" placeholder="Enter your doubt here..." required></textarea>
          <button type="submit">Submit</button>
        </form>
        <div id="doubtResponse" class="response"></div>

        <h3>Previous Doubts</h3>
        <div id="previousDoubts" class="previous-doubts">
          
        </div>
      </div>

    </>
  );
};

export default ViewVideo;
