

import React, { useEffect } from 'react';


function Placement() {
    useEffect(() => {
        
        const studentUniqueId = localStorage.getItem("student_unique_id");
        console.log("Student Unique ID:", studentUniqueId);
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    return (
        <div>
            {/* Your component JSX */}
        </div>
    );
}

export default Placement;

