import React, { useEffect, useState } from 'react';
import "./PlacementProcess.css"

const PlacementProcess = () => {
  const [data, setData] = useState([]);

  // Fetch the JSON data
  useEffect(() => {
      fetch('/policies.json')
          .then(response => response.json())
          .then(data => setData(data))
          .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="card-list">
    {data.map((item, index) => (
        <div key={index} className="card">
            <div className="circle"></div>
            <p>{item}</p>
        </div>
    ))}
</div>
  
  );
}

export default PlacementProcess
