import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import Assignmentcsv from './assignment_masters.csv'; // Path to your CSV file
import './Assignment.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import API_BASE_URL from './config'; 

function YourComponent() {
  const [topics, setTopics] = useState([]);
  const [pdfUrls, setPdfUrls] = useState({});
  const [openAssignmentSection, setOpenAssignmentSection] = useState(null);
  // eslint-disable-next-line
  const [assignmentAPI, setAssignmentAPI] = useState(false);
  // eslint-disable-next-line
  const [assignmentopennot, setassignmentopennot] = useState([]);
  const [AssignmentModalOpen, setAssignmentModalOpen] = useState(false);
  // eslint-disable-next-line
  const [CurrentTopicClick, setCurrentTopicClick] = useState();
  // eslint-disable-next-line
  const [CurrentTopicPercentage, setCurrentTopicPercentage] = useState();
  const [CurrentTopicName, setCurrentTopicName] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [AssignmentName, setAssignmentName] = useState();
  // eslint-disable-next-line
  const [SubmitStatus, setSubmitStatus] = useState("not-completed");
  const [AssignmentsStatus, setAssignmentsStatus] = useState({});



  const scrollPositionRef = useRef(0);

  useEffect(() => {
    fetch(Assignmentcsv)
      .then((response) => response.text())
      .then((data) => {
        Papa.parse(data, {
          header: true, // This will ignore the first line
          complete: (results) => {
            const AssignmentTopicList = results.data.map((row, index) => ({
              Index: row['Index'],
              Main_Topic: row['Main_Topic'],
              percentage_main_topic: row['percentage_main_topic'],
              percentage_per_assignment: row['percentage_per_assignment']
            }));
            setTopics(AssignmentTopicList);
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching or parsing CSV file:', error);
      });

    async function fetchAssignmentOpenNotStatus() {
      const studentUniqueId = localStorage.getItem("student_unique_id");
      const token = localStorage.getItem("token");
      if (studentUniqueId && token) {
        try {
          const response = await fetch(`${API_BASE_URL}/assignmentopennot_status/${studentUniqueId}?token=${token}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setassignmentopennot(data.final_df);

          // 
          const formData = new FormData();
          formData.append("student_id", studentUniqueId);
          formData.append("token", token);

          if (studentUniqueId && token) {
            try {

              const response = await fetch(`${API_BASE_URL}/assignment_status/`, {
                method: "POST",
                body: formData,
              });
              const res = await response.json(); // Correctly call .json() to parse the response
              console.log(res)

              if (response.ok) {
                const newAssignments = res.student_assignments.reduce((acc, assignment) => {
                  acc[assignment['assignment-unique-name']] = assignment['assignment-unique-status'];
                  return acc;
                }, {});

                setAssignmentsStatus(newAssignments);

                console.log('Assignments:', newAssignments);

              }
            }

            catch (error) {
              console.error("Error:", error);

            }

          }




          // 
        } catch (error) {
          console.error('Error fetching assignment open status:', error);
          return [];
        }
      }



    }


    fetchAssignmentOpenNotStatus();
  }, []);

  const handleAssignmentClick = (assignmentName) => {
    setAssignmentName(assignmentName)
    const element = assignmentName.slice(16);
    setCurrentTopicName(element);
    const abc = getStatusForTopic(element);
    console.log(element, assignmentopennot)
    console.log(abc)

    if (abc) {
      if (openAssignmentSection === assignmentName) {
        setOpenAssignmentSection(null); // Close the section if it's already open
      } else {
        setOpenAssignmentSection(assignmentName); // Open the section
      }
    } else {
      // Store the current scroll position before opening the modal
      scrollPositionRef.current = window.scrollY;
      setAssignmentModalOpen(true);
    }

    if (!pdfUrls[assignmentName]) {
      fetch(`${API_BASE_URL}/get-assignment/${assignmentName}`)
        .then((response) => {
          if (response.ok) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              setPdfUrls((prevUrls) => ({ ...prevUrls, [assignmentName]: url }));
              setAssignmentAPI(true); // Set API call status to true
            });
          } else {
            console.error('Failed to fetch assignment:', response.statusText);
            setAssignmentAPI(false); // Set API call status to false
          }
        })
        .catch((error) => {
          console.error('Error fetching assignment:', error);
          setAssignmentAPI(false); // Set API call status to false
        });
    } else {
      setAssignmentAPI(true);
    }
  };

  const getStatusForTopic = (topic) => {
    const trimmedTopic = topic.trim().toLowerCase();
    console.log("Topic parameter:", trimmedTopic);
    console.log("Assignment open not data:", assignmentopennot);

    const topicData = assignmentopennot.find(item => {
      const trimmedMainTopic = item.main_topic.trim().toLowerCase();
      console.log("Checking item:", trimmedMainTopic);
      return trimmedMainTopic === trimmedTopic;
    });

    console.log("Found topic data:", topicData);

    return topicData ? topicData.differenc_status : null;
  };
  const closeModal = () => {
    setAssignmentModalOpen(false);
    // Restore the scroll position after closing the modal
    setTimeout(() => {
      window.scrollTo(0, scrollPositionRef.current);
    }, 0); // Ensure the scroll happens after the state update
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 100 * 1024 * 1024; // 100 MB in bytes

    if (file) {
      if (file.type !== 'application/zip' && file.name.split('.').pop() !== 'zip') {
        alert("Please upload a zip file.");
        event.target.value = ''; // Clear the input
      } else if (file.size > maxSize) {
        alert("File size exceeds 100 MB. Please upload a smaller file.");
        event.target.value = ''; // Clear the input
      } else {
        // Set the selected file state
        setSelectedFile(file);

      }
    }
  };

  const handleUpload = async () => {

    if (selectedFile) {

      const studentUniqueId = localStorage.getItem("student_unique_id");
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("student_id", studentUniqueId);
      formData.append("token", token);
      formData.append("file", selectedFile);
      formData.append("save_name", AssignmentName);
      formData.append("status", "submit_notcheck");

      try {
        const response = await fetch(`${API_BASE_URL}/upload_assignment/`, {
          method: "POST",
          body: formData,
        });
        const res = await response.json(); // Correctly call .json() to parse the response
        console.log(res)

        console.log("Response:", response);


        if (response.ok) {
          const newAssignments = res.student_assignments.reduce((acc, assignment) => {
            acc[assignment['assignment-unique-name']] = assignment['assignment-unique-status'];
            return acc;
          }, {});

          setAssignmentsStatus(newAssignments);

          console.log('Assignments:', newAssignments);
          alert("File uploaded successfully!");
        } else {
          alert("Error uploading file!", res.detail);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error uploading file!");
      }


    }

    else {
      alert('No file selected.');
    }


  }


  return (
    <div className='assignment-div'>

      <ul >
        {!AssignmentModalOpen && topics.map((topic, index) => (


          <li key={index} className='li-outer'>

            <button
              className="parent-topic"
              onClick={() => handleAssignmentClick(`${topic.Index} ${topic.Main_Topic}`)}
            >
              <div className="label-index"> {topic.Index}</div>
              <div className="label-topicmain"> {topic.Main_Topic}</div>
              <div className="label-perassign"> {topic.percentage_per_assignment} %</div>
              <span className="label-completed"> {SubmitStatus}</span>
              <span className="label-checked"> not-checked</span>
            </button>

            <div className='pdf-out'>
              {openAssignmentSection === `${topic.Index} ${topic.Main_Topic}` && (
                pdfUrls[`${topic.Index} ${topic.Main_Topic}`] ? (
                  <div className="pdf-container">
                    {pdfUrls[`${topic.Index} ${topic.Main_Topic}`] && (
                      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={pdfUrls[`${topic.Index} ${topic.Main_Topic}`]} />
                      </Worker>
                    )}
                  </div>
                ) : (
                  <div className="assignment-notification">
                    This assignment, <strong style={{ color: 'red' }}>{`  ${topic.Index} - ${topic.Main_Topic}`}</strong>, has not been updated by management yet.
                  </div>
                )
              )}
            </div>

            {openAssignmentSection === `${topic.Index} ${topic.Main_Topic}` &&
              <div className="notes-section">
                <h2>Assignment Submission Instructions</h2>
                <p>After completing the assignment, make a zip file of it and ensure its size is less than 100 MB. Upload the file then, and our software will automatically rename the submission as per the current assignment submitted.</p>
              </div>}




            {openAssignmentSection === `${topic.Index} ${topic.Main_Topic}` && (
              <div className='upload-div'>
                {AssignmentsStatus[AssignmentName] === "submit_notcheck" ? (
                  <div className="assignment-status">
                    Assignment is Submitted. Wait for the mentor to check and return back to you shortly.
                  </div>
                ) : (
                  <>
                    <div className='upload-1'>
                      <div className='u1'>Upload {topic.Index} :</div>
                      <div className="file-input-container">
                        <input
                          type="file"
                          accept=".zip"
                          className="file-input"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>

                    <div className='upload-2'>
                      <div id="file-name-display">Renamed File Name is: <br /><strong style={{ color: 'red' }}>{AssignmentName}.zip</strong></div>
                      <div className='u3'>
                        <button className='u3-btn' onClick={handleUpload}>Upload</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>

      {AssignmentModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p className="model-text">
              <strong>Hey buddy,</strong> <br /><br />
              <div className="modal-font">
                It's not the right time to start the Assignment Section. Focus on finishing the current syllabus of
                <strong className="underline-text"> {CurrentTopicName} Video Section </strong>
                first.<br /><br />
                Once that's done, you can start solving the assignment.
              </div>
            </p>
            <button className="modal-btn" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default YourComponent;
