import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import "./Navbarmodule.css";

const Navbar = ({ setPage }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to toggle drawer

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen); // Toggle the drawer open/close
  };

  // Function to navigate to a new page and close the drawer
  const handleNavigation = (page) => {
    setPage(page);  // Change page
    setIsDrawerOpen(false); // Close the drawer
  };

  return (
    <nav className="NavMain">
      <div className="companyname">sneh-achievers</div>

      {/* Drawer toggle button visible on small screens */}
      <div className="drawer-icon" onClick={toggleDrawer}>
        <FaBars />
      </div>

      {/* Navigation buttons for larger screens or drawer */}
      <div className={`buttons ${isDrawerOpen ? "drawer-open" : ""}`}>
        <button className="NavButton" onClick={() => handleNavigation("Home")}>
          Home
        </button>
        <button className="NavButton" onClick={() => handleNavigation("StudentLogin")}>
          Student Login
        </button>
        <button className="NavButton" onClick={() => handleNavigation("PlacementProcess")}>
          Placement policies & Fee structure
        </button>
        <button className="NavButton" onClick={() => handleNavigation("Business")}>
          Business Enquiry
        </button>
      </div>

      <div className="contact">
        <a href="tel:+918208937591" className="phone-number">+918208937591</a>
      </div>
    </nav>
  );
};

export default Navbar;
