import React, { useState } from "react";
import CompleteSyllabus from "./CompleteSyllabus";
import Assignment from "./Assignment";
import Placement from "./Placement"
import MyProfile from "./MyProfile"
import Navbar2 from "./NavBar2"
import "./StudentDashBoad.css"

const StudentDashBoad = () => {
  const [currentPage, setCurrentPage] = useState("CompleteSyllabus"); // Capitalized initial page
  const renderPage = () => {
    switch (currentPage) {
      case "Assignment":
        return <Assignment />;
      case "Placement":
        return <Placement />;
      case "MyProfile":
        return <MyProfile />;
      default:
      case "CompleteSyllabus":
        return <CompleteSyllabus />;
    }
  };

  return (<>
    <div className="uppar-nav2">
      <div className="NavNames2">
        <Navbar2 className="NavNames2" setPage={setCurrentPage} />
      </div>
    </div>
    {renderPage()}
  </>);
};

export default StudentDashBoad;
